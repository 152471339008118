/* General app styles */
.App {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.threejs-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Make sure the canvas is behind the text */
}

/* Overlay text that centers on the page */
.overlay {
  z-index: 2;
  position: absolute;
  color: white;
  text-align: center;
}

.overlay h1 {
  font-size: 5rem;
  margin: 0;
}

.overlay h2 {
  font-size: 2rem;
  color: #00ff7f;
  margin-bottom: 20px;
}

.overlay p {
  font-size: 1rem;
  /* max-width: 600px; */
  margin: 0 auto;
}
